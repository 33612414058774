<template>
    <p>
        <span class="address-label">street:</span> {{ address.address }}<br>
        <span class="address-label">city:</span> {{ address.city }}<br>
        <span class="address-label">zip:</span> {{ address.zip }}<br><br>
        <span :class="'flag-icon flag-input-icon flag-icon-' + address.country.toLowerCase()" />&nbsp;&nbsp;{{ address.countryFullName }}<br>
    </p>
</template>

<script>

export default {
    name: 'AccountSettingsAddress',
    props: {
        address: {
            type: Object,
            required: true
        }
    }
}

</script>

<style lang="scss">
.address-label {
    color: $light-grey;
    font-size: 14px;
    font-weight: 400;
}
</style>
